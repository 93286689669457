import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import {
  renderSEO,
  ROUTES,
  isBrowser,
  LoadingIndicator,
} from "@lesmills/gatsby-theme-common"

const MaintenanceSection = React.lazy(() => import("../components/Maintenance"))

type Props = {|
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const MaintenancePage = (props: Props) => {
  const { data, pageContext } = props
  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicMaintenancePage = {} } = data
  const pageContent = prismicMaintenancePage.data || {}
  const layoutData = (data.prismicLayout && data.prismicLayout.data) || {}

  return (
    <>
      {renderSEO(pageContent, ROUTES(lang).MAINTENANCE, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <MaintenanceSection
            data={pageContent}
            layoutData={layoutData}
            lang={lang}
          />
        </React.Suspense>
      )}
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicMaintenancePage(lang: { eq: $lang }) {
      data {
        maintenance_background_photo {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        maintenance_content {
          raw
          text
        }
        maintenance_go_back_button_label {
          raw
          text
        }
        maintenance_icon {
          alt
          url
        }
        maintenance_title {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
  }
`
export default withPreview(MaintenancePage)
